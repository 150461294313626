
import {computed, defineComponent, onMounted} from "vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {ICase} from "@/core/entity/ICase";
import FormCase from "@/views/case/FormCase.vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {LoadEntity} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import {onUpdated} from "@vue/runtime-core";

export default defineComponent({
  name: "Update",
  components: {FormCase},
  setup() {
    const store = useStore();
    const router = useRouter();

    const caze = computed<ICase>(() => store.state.CaseModule.case);
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Update Case', [
        {link: true, router: '/cases', text: 'Search Case'},
        {link: true, router: '/cases/' + caze?.value?.id, text: 'View Case'},
        {link: false, router: '', text: 'Update Case'}
      ])
    })
    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('Update Case', [
        {link: true, router: '/cases', text: 'Search Case'},
        {link: true, router: '/cases/' + caze?.value?.id, text: 'View Case'},
        {link: false, router: '', text: 'Update Case'}
      ])
    })
    const onUpdatedCase = (ca) => {
      console.log(ca);
      router.push({
        name: "caseOverview",
        params: {id: ca.id}
      });
    }
    return {
      caze,
      onUpdatedCase,
      ...LoadEntity(Actions.LOAD_CASE),
    }
  }
})
